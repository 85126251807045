import { useMemo } from "react";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { type Producer, produce } from "immer";
import { z } from "zod";
import { useShallow } from "zustand/shallow";
import { backendService } from "@/lib/backend";
import { extractionWrapper, useExtractedMutation } from "@/lib/backend/utils";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryKey_myData = ["user", "me", "data"] as const;
export const queryFn_myData = (context: QueryFunctionContext<typeof queryKey_myData>) =>
	extractionWrapper(backendService.user.usersControllerGetAppData({ signal: context.signal }));

type QueryOptions = UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_myData>>,
	Error,
	Awaited<ReturnType<typeof queryFn_myData>>,
	typeof queryKey_myData
>;

export const MyDataSchema = z
	.object({
		modal: z
			.object({
				appExplainer: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				reflectionExplainerGeneral: z
					.object({ seen: z.coerce.boolean().default(false) })
					.default({}),
				friendCheckExplainer: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				friendCheckCompleted: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				coreValuesCompileExplainer: z
					.object({ seen: z.coerce.boolean().default(false) })
					.default({}),
			})
			.default({}),
		reflection: z
			.object({
				questions: z
					.record(
						z
							.object({
								content: z.string(),
								options: z.array(z.string()),
							})
							.optional(),
					)
					.default({}),
			})
			.default({}),
		coreValues: z
			.object({
				savedFriendCheck: z.array(z.string()).default([]),
			})
			.default({}),
	})
	.default({});

export const query_myData = {
	queryFn: queryFn_myData,
	queryKey: queryKey_myData,
} satisfies QueryOptions;

export function useMyData(options?: Partial<QueryOptions>) {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const query = useQuery({
		...query_myData,
		enabled: isAuthenticated,
		...options,
	});
	const parseResult = MyDataSchema.safeParse(query.data);
	let data: z.infer<typeof MyDataSchema> = MyDataSchema.parse({});

	if (parseResult.success) {
		data = parseResult.data;
	}

	const mutation = useExtractedMutation({
		mutationKey: queryKey_myData,
		fn: (variables: Producer<z.infer<typeof MyDataSchema>>) =>
			backendService.user.usersControllerUpdateAppData(
				produce(data as unknown as Record<string, string | number | boolean>, variables),
			),
		onSuccess: () => query.refetch(),
	});

	return useMemo(() => [data, { query, mutation }] as const, [data]);
}
