import { IonHeader, IonIcon, IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { FormInput } from "@/components/Form/Input";
import { FormTextarea } from "@/components/Form/Textarea";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { TitleIcon } from "@/components/TitleIcon";
import { Toast } from "@/lib/capacitor/Toast";
import { getFullName } from "@/lib/getFullName";
import { xMarkIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { lifebuoyIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMe } from "@/lib/query/functions/user/me";
import { SentryReact } from "@/lib/sentry";
import { useSelectorModalStore } from "@/lib/store/modal";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function ModalAppFeedback() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.appFeedback();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const textareaRef = useRef<HTMLIonTextareaElement>(null);

	const [me] = useMe();

	const form = useZodForm<Parameters<(typeof SentryReact)["captureFeedback"]>[0]>({
		defaultValues: {
			message: "",
			email: me?.email,
			name: me ? (getFullName(me) ?? me.id) : "",
		},
		onSubmit: async ({ value }) => {
			posthog.capture("User Feedback");
			SentryReact.captureFeedback(value);
			Toast.show({
				text: t("modal.app.feedback.message.success"),
				duration: "long",
			});
			modalRef.current?.dismiss();
		},
	});

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("appFeedback")}
			onDidPresent={() => textareaRef.current?.setFocus()}
			onWillDismiss={() => dismiss("appFeedback")}
		>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-py-2">
					<Button
						slot="secondary"
						className="touch-target min-h-0 ion-bg-a-brown-300 ion-bg-f-brown-300 ion-bg-transparent ion-border-brown-300 ion-text-a-brown-600 ion-text-brown-600 ion-text-f-brown-600 ion-text-h-brown-600 ion-rounded-full ion-p-2 ion-border"
						onClick={() => modalRef.current?.dismiss()}
					>
						<IonIcon slot="icon-only" icon={xMarkIcon} className="size-5" />
					</Button>
				</Toolbar>
			</IonHeader>
			<Content fullscreen inModal>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-grow flex-col gap-6"
				>
					<TitleIcon icon={lifebuoyIcon} />
					<div className="flex flex-col gap-2 text-brown-700">
						<IonText className="text-4xl font-bold">{t("modal.app.feedback.name")}</IonText>
						<IonText>{t("modal.app.feedback.text")}</IonText>
					</div>

					<div className="flex flex-col gap-4">
						<form.Field
							name="name"
							validators={{
								onChange: z.string().regex(/^^[^@]*$/, t("fields.name.errors.no-email")),
							}}
							children={(field) => (
								<FormInput
									field={field}
									label={t("fields.name.label")}
									placeholder={t("fields.name.placeholder")}
								/>
							)}
						/>

						<form.Field
							name="email"
							validators={{ onChange: z.string() }}
							children={(field) => (
								<FormInput
									field={field}
									label={t("fields.email.label")}
									placeholder={t("fields.email.placeholder")}
								/>
							)}
						/>

						<form.Field
							name="message"
							validators={{ onSubmit: z.string().min(1) }}
							children={(field) => (
								<FormTextarea
									ref={textareaRef}
									field={field}
									autocapitalize="sentences"
									label={t("fields.message.label")}
									placeholder={t("fields.message.placeholder")}
									className="!min-h-32 plt-desktop:!min-h-20"
								/>
							)}
						/>
					</div>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<Button
								type="submit"
								disabled={!canSubmit}
								expand="block"
								className="m-0 mt-auto h-12 ion-rounded-2xl part-native:text-lg part-native:font-bold"
							>
								{isSubmitting ? t("loading") : t("modal.app.feedback.action")}
							</Button>
						)}
					/>
				</form>
			</Content>
		</IonModal>
	);
}
