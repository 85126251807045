import { IonBackButton, IonButtons, IonHeader, IonIcon, IonPage, IonTitle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { FormInput } from "@/components/Form/Input";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type UpdateUserDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { Toast } from "@/lib/capacitor/Toast";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { pencilSquareIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { cx } from "@/lib/style/cva.config";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function SettingsProfile() {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const PreferredHaptics = usePreferredHaptics();

	const [me] = useMe();

	const mutationSelfUpdate = useExtractedMutation({
		mutationKey: ["auth", "sign-up", "profile"],
		fn: (variables: Partial<UpdateUserDto>) =>
			backendService.user.usersControllerUpdateMe(variables),
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["user", "me"] }),
	});

	const form = useZodForm({
		defaultValues: {
			name: me?.name ?? "",
		},
		onSubmit: ({ value }) =>
			mutationSelfUpdate.mutateAsync(value, {
				onSuccess: () => Toast.show({ text: t("settings.profile.message.success") }),
			}),
	});

	return (
		<IonPage>
			<IonHeader translucent>
				<Toolbar className="plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary" onClick={() => PreferredHaptics.impact()}>
						<IonBackButton
							defaultHref={Routes.Settings()}
							color="light"
							className="touch-target min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>

					<IonTitle>{t("settings.profile.name")}</IonTitle>
				</Toolbar>
			</IonHeader>
			<Content fullscreen>
				<IonHeader collapse="condense">
					<Toolbar className="!ion-px-0">
						<IonTitle size="large" className="px-0 text-2xl text-brown-700">
							{t("settings.profile.name")}
						</IonTitle>
					</Toolbar>
				</IonHeader>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-1 flex-col gap-2"
				>
					<form.Field
						name="name"
						validators={{
							onChange: z
								.string()
								.min(1, t("fields.name.errors.min", { count: 1 }))
								.regex(/^^[^@]+$/, t("fields.name.errors.no-email")),
						}}
						children={(field) => (
							<FormInput
								field={field}
								label={t("fields.name.label")}
								required
								autocomplete="given-name"
								placeholder={t("fields.givenName.placeholder")}
							>
								<IonIcon slot="end" icon={pencilSquareIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<Button
								type="submit"
								disabled={!canSubmit}
								expand="block"
								className={cx(
									"m-0 mt-auto h-12 ion-rounded-2xl part-native:text-lg part-native:font-bold",
								)}
							>
								{isSubmitting ? t("loading") : t("save")}
							</Button>
						)}
					/>
				</form>
			</Content>
		</IonPage>
	);
}
