import { IonIcon } from "@ionic/react";
import { type ReactNode } from "react";
import { exclamationTriangleIcon } from "@/lib/icons/@heroicons/react/24/outline";

interface NotFoundProps {
	title: ReactNode;
	description?: ReactNode;
	action?: ReactNode;
	icon?: string;
}

export function NotFound({
	title,
	description,
	action,
	icon = exclamationTriangleIcon,
}: NotFoundProps) {
	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-3 p-4 text-center">
			<IonIcon icon={icon} className="mb-3 size-32 text-brown-700" />
			<h1 className="text-pretty text-xl font-bold text-brown-700">{title}</h1>
			{description && <p className="text-balance text-base text-brown-600">{description}</p>}
			{action && <div className="mt-3">{action}</div>}
		</div>
	);
}
