import { useMemo } from "react";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/shallow";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryKey_myProgression = ["user", "me", "progression"] as const;
export const queryFn_myProgression = (
	context: QueryFunctionContext<typeof queryKey_myProgression>,
) =>
	extractionWrapper(
		backendService.user.usersControllerGetMyProgressionStatus({ signal: context.signal }),
	);

type QueryOptions = UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_myProgression>>,
	Error,
	Awaited<ReturnType<typeof queryFn_myProgression>>,
	typeof queryKey_myProgression
>;

export const query_myProgression = {
	queryFn: queryFn_myProgression,
	queryKey: queryKey_myProgression,
	initialData: {
		core_values: "NOT_STARTED",
		friend_check: "NOT_STARTED",
		reflection: "NOT_STARTED",
		chosen_core_values: "NOT_STARTED",
	},
} satisfies QueryOptions;

export function useMyProgression(options?: Partial<QueryOptions>) {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const query = useQuery({
		...query_myProgression,
		enabled: isAuthenticated,
		...options,
	});
	const myProgression = query.data || query_myProgression.initialData;

	return useMemo(
		() => [myProgression, query] as const,
		[myProgression, query.status, query.dataUpdatedAt, query.data],
	);
}
