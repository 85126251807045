import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonLabel,
	IonNote,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonSkeletonText,
	IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthAvatar } from "@/components/Auth/Avatar";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { SettingsSection } from "@/components/Settings/Section";
import { SectionItem } from "@/components/Settings/SectionItem";
import { AppleLogo } from "@/components/SocialButton/Apple";
import { GoogleLogo } from "@/components/SocialButton/Google";
import { LANGUAGES } from "@/i18n";
import { backendService } from "@/lib/backend";
import { type UpdateUserDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { Capacitor } from "@/lib/capacitor";
import { Camera } from "@/lib/capacitor/Camera";
import { Dialog } from "@/lib/capacitor/Dialog";
import { LocalNotifications } from "@/lib/capacitor/LocalNotifications";
import { PushNotifications } from "@/lib/capacitor/PushNotifications";
import { Share } from "@/lib/capacitor/Share";
import { Toast } from "@/lib/capacitor/Toast";
import {
	COLOR_SCHEMES,
	PAGE_AI_USAGE_URL,
	PAGE_PRIVACY_POLICY_URL,
	PAGE_TERMS_AND_CONDITIONS_URL,
} from "@/lib/constants";
import { getAvatarUrl } from "@/lib/getAvatarUrl";
import { getFullName } from "@/lib/getFullName";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon, arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { chevronUpDownIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useConfig } from "@/lib/posthog/config";
import { query_canShare } from "@/lib/query/functions/@capacitor/share/canShare";
import { queryKey_myData } from "@/lib/query/functions/user/data";
import { queryKey_me, useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { SentryCapacitor, SentryReact } from "@/lib/sentry";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { type ModalKeys, useSelectorModalStore } from "@/lib/store/modal";
import { usePreferencesStore } from "@/lib/store/preferences";

export function Settings() {
	const { t } = useTranslation();
	const PreferredHaptics = usePreferredHaptics();
	const {
		reducedHaptics,
		setReducedHaptics,
		language: languageSetting,
		setLanguage,
		colorScheme,
		setColorScheme,
	} = usePreferencesStore();
	const unauthenticate = useSelectorAuthStore.use.unauthenticate();
	const platform = Capacitor.Platform;
	const queryClient = useQueryClient();
	const config = useConfig();
	const presentModal = useSelectorModalStore.use.present();

	const mutationSelfUpdate = useExtractedMutation({
		mutationKey: ["auth", "sign-up", "profile"],
		fn: (variables: Partial<UpdateUserDto>) =>
			backendService.user.usersControllerUpdateMe(variables),
		onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey_me }),
	});

	const mutationAppData = useExtractedMutation({
		mutationKey: queryKey_myData,
		fn: (variables: Record<string, string | number | boolean>) =>
			backendService.user.usersControllerUpdateAppData(variables),
		onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey_myData }),
	});

	const { data: canShare } = useQuery(query_canShare);

	const [me] = useMe();

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary" onClick={() => PreferredHaptics.impact()}>
						<IonBackButton
							defaultHref={Routes.Dashboard()}
							color="light"
							className="touch-target min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<Content withBottomPadding className="!ion-pt-0 part-scroll:gap-6">
				<div className="mb-2 flex flex-col items-center gap-3">
					<AuthAvatar
						withEdit
						parts={{ img: { src: getAvatarUrl(me) } }}
						onClick={() =>
							Camera.checkPermissions().then(() =>
								Camera.getPhoto({ resultType: Camera.ResultType.DataUrl }).then(async (photo) => {
									presentModal("cropProfilePicture", {
										imageUrl: photo.dataUrl,
										onCompleted: async (imageUrl) => {
											mutationSelfUpdate.mutate({
												avatar: await fetch(imageUrl ?? "").then(
													(response) => response.blob() as unknown as File,
												),
											});
										},
									});
								}),
							)
						}
					/>

					<div className="flex flex-col items-center gap-1">
						<IonText data-ph-no-capture className="text-xl font-bold text-brown-700">
							{!me ? (
								<IonSkeletonText animated className="h-[1.375rem] w-40 ion-rounded-lg" />
							) : (
								getFullName(me)
							)}
						</IonText>
						<IonText data-ph-no-capture className="flex flex-row items-center gap-2 text-brown-500">
							{!me ? (
								<IonSkeletonText animated className="h-5 w-40 ion-rounded-lg" />
							) : (
								<>
									{me.authSource === "GOOGLE" && <GoogleLogo />}
									{me.authSource === "APPLE" && <AppleLogo />}
									{!me.email.endsWith("privaterelay.appleid.com") && me.email}
								</>
							)}
						</IonText>
					</div>
				</div>

				<SettingsSection header={t("settings.name")}>
					{config.development && (
						<SectionItem>
							<IonSelect
								label={t("language.name")}
								value={languageSetting}
								onIonChange={(event) => setLanguage(event.detail.value)}
								toggleIcon={chevronUpDownIcon}
								expandedIcon={chevronUpDownIcon}
							>
								{(["system", ...LANGUAGES] as const).map((language) => (
									<IonSelectOption key={language} value={language}>
										{t(`language.options.${language}`)}
									</IonSelectOption>
								))}
							</IonSelect>
						</SectionItem>
					)}
					<SectionItem detail detailIcon={arrowRightIcon} routerLink={Routes.Profile()}>
						<IonLabel>{t("settings.profile.name")}</IonLabel>
					</SectionItem>
				</SettingsSection>

				<SettingsSection header={t("settings.preferences")}>
					<SectionItem>
						<IonSelect
							label={t("reduced-haptics.name")}
							value={reducedHaptics}
							onIonChange={(event) => setReducedHaptics(Boolean(event.detail.value))}
							toggleIcon={chevronUpDownIcon}
							expandedIcon={chevronUpDownIcon}
						>
							{[true, false].map((value) => (
								<IonSelectOption key={String(value)} value={value}>
									{t(`reduced-haptics.options.${value}`)}
								</IonSelectOption>
							))}
						</IonSelect>
					</SectionItem>
					{config.development && (
						<SectionItem>
							<IonSelect
								label={t("color-scheme.name")}
								value={colorScheme}
								onIonChange={(event) => setColorScheme(event.detail.value)}
								toggleIcon={chevronUpDownIcon}
								expandedIcon={chevronUpDownIcon}
							>
								{(["system", ...COLOR_SCHEMES] as const).map((colorScheme) => (
									<IonSelectOption key={colorScheme} value={colorScheme}>
										{t(`color-scheme.options.${colorScheme}`)}
									</IonSelectOption>
								))}
							</IonSelect>
						</SectionItem>
					)}
					<SectionItem detail detailIcon={arrowRightIcon} routerLink={Routes.Notifications()}>
						<IonLabel>Meldingen</IonLabel>
					</SectionItem>
				</SettingsSection>

				<SettingsSection header={t("settings.integrations")}>
					<SectionItem button detailIcon={arrowRightIcon} onClick={() => presentModal("impolitic")}>
						<IonLabel>{t("integrations.impolitic.name")}</IonLabel>
					</SectionItem>
				</SettingsSection>

				<SettingsSection header={t("settings.about-this-app")}>
					<SectionItem>
						<IonLabel>{t("settings.app-version")}</IonLabel>
						<IonNote slot="end" className="w-1/2 shrink truncate text-end text-brown-500">
							{(function () {
								const release =
									SentryCapacitor.getCurrentScope().getSession()?.release || __SENTRY_RELEASE__;

								if (release) {
									if (release.includes("@")) {
										return release.split("@")[1];
									}

									return release;
								}

								return "???";
							})()}
						</IonNote>
					</SectionItem>
					<SectionItem
						button
						detail
						detailIcon={arrowRightIcon}
						href={PAGE_TERMS_AND_CONDITIONS_URL}
						target="_blank"
						rel="noreferrer"
					>
						<IonLabel>{t("terms-and-conditions")}</IonLabel>
					</SectionItem>
					<SectionItem
						button
						detail
						detailIcon={arrowRightIcon}
						href={PAGE_PRIVACY_POLICY_URL}
						target="_blank"
						rel="noreferrer"
					>
						<IonLabel>{t("privacy-policy")}</IonLabel>
					</SectionItem>
					<SectionItem
						button
						detail
						detailIcon={arrowRightIcon}
						href={PAGE_AI_USAGE_URL}
						target="_blank"
						rel="noreferrer"
					>
						<IonLabel>{t("usage-chats")}</IonLabel>
					</SectionItem>
					<SectionItem
						button
						detail
						detailIcon={arrowRightIcon}
						onClick={() => presentModal("appFeedback")}
					>
						<IonLabel>{t("modal.app.feedback.name")}</IonLabel>
					</SectionItem>
					{(config.development || platform !== "web") && (
						<SectionItem
							button
							detail
							detailIcon={arrowRightIcon}
							href="https://imperfect.so/"
							target="_blank"
						>
							<IonLabel>{t("settings.rate-this-app")}</IonLabel>
						</SectionItem>
					)}
					{(config.development || canShare) && (
						<SectionItem
							button
							detail
							detailIcon={arrowRightIcon}
							onClick={() =>
								Share.share({
									title: t("settings.share-this-app.title"),
									dialogTitle: t("settings.share-this-app.title"),
									text: t("settings.share-this-app.text"),
									url: "https://imperfect.so",
								})
							}
						>
							<IonLabel>{t("settings.share-this-app.name")}</IonLabel>
						</SectionItem>
					)}
				</SettingsSection>

				{config.development && (
					<>
						<SettingsSection header="[DEV] Modals">
							{Object.entries({
								appExplainer: "App: Explainer",
								appFeedback: "App: Feedback",
								coreValuesCompileExplainer: "Core Values: Compile - Explainer",
								coreValuesCompileGeneral: "Core Values: Compile - General",
								coreValuesDetail: "Core Values: Detail",
								friendCheckAnalysisGenerate: "Friend Check: Analysis - Generate",
								friendCheckAnalysisView: "Friend Check: Analysis - View",
								friendCheckCompleted: "Friend Check: Completed",
								friendCheckExplainer: "Friend Check: Explainer",
								reflectionAnalysisGenerate: "Reflection: Analysis - Generate",
								reflectionAnalysisView: "Reflection: Analysis - View",
								reflectionCompleted: "Reflection: Completed",
								reflectionExplainerFirstQuestion: "Reflection: Explainer - First Question",
								reflectionExplainerGeneral: "Reflection: Explainer - General",
								reflectionQuestionCompleted: "Reflection: Question - Completed",
								reflectionQuestionFirstAnswer: "Reflection: Question - First Answer",
								devDeleteFriendcheck: "[DEV] Delete Friend Check",
								deleteAccount: "Delete account",
								cropProfilePicture: "Crop Profile Picture",
								pushNotificationsDenied: "Push Notifications Denied",
								impolitic: "Impolitic",
							} satisfies Record<ModalKeys, string>).map(([key, name]) => (
								<SectionItem
									key={key}
									button
									detail
									detailIcon={arrowRightIcon}
									onClick={() => presentModal(key as any)}
								>
									<IonLabel>{name}</IonLabel>
								</SectionItem>
							))}
						</SettingsSection>

						<SettingsSection header="[DEV] Pages">
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								routerLink={Routes.SharedFriendCheck()}
							>
								<IonLabel>FriendCheck: Information</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								routerLink={Routes.SharedFriendCheckQuestions()}
							>
								<IonLabel>FriendCheck: Questions</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								routerLink={Routes.SharedFriendCheckThankYou()}
							>
								<IonLabel>FriendCheck: Thank You</IonLabel>
							</SectionItem>
						</SettingsSection>

						<SettingsSection header="[DEV] Sentry">
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() => {
									throw new Error("Sentry Test Error");
								}}
							>
								<IonLabel>Throw error</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() =>
									SentryReact.captureFeedback({
										message: "Provided test user feedback!",
										email: me?.email,
										name: me ? (getFullName(me) ?? me?.id) : "",
									})
								}
							>
								<IonLabel>Provide user feedback</IonLabel>
							</SectionItem>
						</SettingsSection>

						<SettingsSection header="[DEV] Delete">
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() => presentModal("devDeleteFriendcheck")}
							>
								<IonLabel>Delete Friend Check</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={async () => {
									await backendService.reflection.reflectionControllerDeleteAnswerForLatestVersion();
									Toast.show({
										text: "Reflection answers deleted",
									});
								}}
							>
								<IonLabel>Delete Reflection Answers</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() => {
									backendService.corevalues.coreValuesControllerDeleteSummary();
									Toast.show({
										text: "Core value summaries deleted",
									});
								}}
							>
								<IonLabel>Delete Core Value Summary</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={async () => {
									await backendService.corevalues.coreValuesControllerDeleteAnalysis();
									Toast.show({
										text: "Core value analysis deleted",
									});
								}}
							>
								<IonLabel>Delete Core Values Analysis</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={async () => {
									await backendService.friendcheck.friendcheckControllerDeleteAnalysis();
									Toast.show({
										text: "FriendCheck analysis deleted",
									});
								}}
							>
								<IonLabel>Delete FriendCheck Analysis</IonLabel>
							</SectionItem>
						</SettingsSection>

						<SettingsSection header="[DEV] API">
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() => mutationAppData.mutate({})}
							>
								<IonLabel>Personal: Reset Data</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() => PushNotifications.register()}
							>
								<IonLabel>Push Notifications: Register device</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() => PushNotifications.unregister()}
							>
								<IonLabel>Push Notifications: Unregister device</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() =>
									backendService.notification.notificationControllerSendMessage({
										title: "[DEV] Test push notification",
										body: "If you see this, it works remotely!",
									})
								}
							>
								<IonLabel>Push Notifications: Send test push notification</IonLabel>
							</SectionItem>
							<SectionItem
								button
								detail
								detailIcon={arrowRightIcon}
								onClick={() =>
									LocalNotifications.schedule({
										id: -1,
										title: "[DEV] Test local notification",
										body: "If you see this, it works locally!",
									})
								}
							>
								<IonLabel>Push Notifications: Send test local notification</IonLabel>
							</SectionItem>
						</SettingsSection>
					</>
				)}
				<div className="my-2 flex flex-col gap-2">
					<Button
						expand="block"
						fill="clear"
						className="text-lg font-bold ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl"
						onClick={() =>
							new Promise((resolve) => {
								if (Capacitor.Platform !== "web") {
									Dialog.confirm({
										title: t("auth.sign-out.dialog.title"),
										message: t("auth.sign-out.dialog.message"),
										okButtonTitle: t("auth.sign-out.dialog.actions.confirm"),
										cancelButtonTitle: t("auth.sign-out.dialog.actions.cancel"),
									}).then((confirmed) => {
										if (confirmed) {
											resolve(undefined);
										}
									});
								} else {
									resolve(undefined);
								}
							}).then(unauthenticate)
						}
					>
						{t("auth.sign-out.name")}
					</Button>

					<Button
						expand="block"
						fill="clear"
						className="text-lg font-bold ion-text-brown-400"
						onClick={() => {
							presentModal("deleteAccount");
						}}
					>
						{t("auth.delete-acount.name")}
					</Button>
				</div>
			</Content>
		</IonPage>
	);
}
