import { IonChip, IonFooter, IonIcon, IonModal, IonSkeletonText, useIonRouter } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { usePostHog } from "posthog-js/react";
import { useCountdown } from "usehooks-ts";
import { z } from "zod";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { backendService } from "@/lib/backend";
import { getAnswer, hasAnswer } from "@/lib/functions/answer";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { query_coreValues } from "@/lib/query/functions/core-values/all";
import { query_reflection } from "@/lib/query/functions/reflection/all";
import { useMyData } from "@/lib/query/functions/user/data";
import { useQueryStream } from "@/lib/query/hooks/useQueryStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { jsonParse } from "@/utils/json/parse";

const MotionIonChip = motion.create(IonChip);

export function ModalReflectionQuestionCompleted() {
	const { t, i18n } = useTranslation();
	const posthog = usePostHog();
	const PreferredHaptics = usePreferredHaptics();
	const router = useIonRouter();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionQuestionCompleted();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [myData] = useMyData();

	const [count, { startCountdown, resetCountdown }] = useCountdown({
		countStart: 1,
		intervalMs: 2_500,
	});

	const queryCoreValues = useQuery({ ...query_coreValues, enabled: modal.isOpen });
	const queryReflection = useQuery({
		...query_reflection,
		enabled: modal.isOpen && !!modal.id,
	});
	const question =
		modal.id && !queryReflection.isFetching
			? queryReflection.data?.find((reflectionSummary) => reflectionSummary.id === modal.id)
			: null;

	const [questionCoreValues, queryQuestionCoreValues] = useQueryStream({
		queryKey: ["reflection", "finalize", modal.id!] as const,
		fn: (params, context) =>
			backendService.reflection.reflectionControllerFinalizeQuestion(context.queryKey[2], params),
		enabled: modal.isOpen && !!question?.id && !question.coreValues?.length,
	});

	const parsedQuestionCoreValues = jsonParse({
		input: questionCoreValues || "[]",
		schema: z.array(
			z.object({
				name: z.string().nullish(),
				score: z.coerce.number().nullish(),
				reason: z.string().nullish(),
			}),
		),
	});

	useEffect(() => {
		if (queryQuestionCoreValues.status === "success") {
			queryReflection.refetch();
		}
	}, [queryQuestionCoreValues.status]);

	const coreValues =
		question && (queryQuestionCoreValues.isFetching || queryQuestionCoreValues.isFetched)
			? parsedQuestionCoreValues
					?.map((coreValue) =>
						queryCoreValues.data?.find((coreValueItem) => coreValueItem.name === coreValue.name),
					)
					.filter(Boolean)
			: null;

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("reflectionQuestionCompleted")}
			onDidPresent={() => startCountdown()}
			onWillDismiss={() => dismiss("reflectionQuestionCompleted", { id: null })}
			onDidDismiss={() => resetCountdown()}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-2 pt-12">
				{!!question && (
					<ReflectionCard className="flex -rotate-1 flex-col gap-3">
						<strong className="text-pretty text-xl font-semibold">{question.question}</strong>
						<div data-ph-no-capture className="font-normal">
							{getAnswer(question.firstAnswer, { language: i18n.language })}
						</div>
						<div className="flex flex-wrap gap-1">
							{!!coreValues && coreValues.length > 0 ? (
								coreValues.map((coreValue) => (
									<CoreValueBadge key={coreValue.id} value={coreValue} />
								))
							) : !!question.coreValues && question.coreValues.length > 0 ? (
								question.coreValues.map((coreValue) => (
									<CoreValueBadge key={coreValue.id} value={coreValue} />
								))
							) : (
								<>
									<IonSkeletonText className="m-0 h-8 w-32 rounded-full" />
									<MotionIonChip
										initial={{ opacity: 0, display: "none" }}
										animate={
											count === 0
												? { opacity: 1, display: "block" }
												: { opacity: 0, display: "none" }
										}
										className="m-0 h-8 min-h-0 bg-brown-200 py-1 text-brown-700"
										onClick={() => {
											PreferredHaptics.impact();
											queryQuestionCoreValues.refetch();
											resetCountdown();
											startCountdown();
										}}
									>
										{t("try-again")}
										<IonIcon icon={arrowPathIcon} className="size-4" />
									</MotionIonChip>
								</>
							)}
						</div>
					</ReflectionCard>
				)}
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<div className="flex flex-col gap-2">
						<Button
							expand="block"
							fill="solid"
							className="text-lg font-bold !ion-rounded-2xl"
							onClick={() => {
								posthog.capture("Modal Reflection Question Completed Confirm");

								modalRef.current?.dismiss();

								const notCompletedItems = queryReflection.data?.filter(
									(question) => !question.isCompleted,
								);

								if (!!notCompletedItems && notCompletedItems?.length > 0) {
									const inProgress = notCompletedItems.find(
										(question) =>
											hasAnswer(question.firstAnswer) ||
											hasAnswer(myData.reflection.questions[question.id]),
									);

									if (inProgress) {
										router.push(
											Routes.ReflectionQuestion({ id: inProgress.id }),
											"forward",
											"replace",
										);
									} else {
										router.push(Routes.Reflection(), "back");
										present("reflectionQuestionFirstAnswer", { id: notCompletedItems[0].id });
									}
								}
							}}
						>
							{t("modal.reflection.question.completed.confirm")}
						</Button>
						<Button
							expand="block"
							fill="clear"
							className="text-lg font-bold ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl"
							onClick={() => {
								posthog.capture("Modal Reflection Question Completed Cancel");
								modalRef.current?.dismiss();
								router.push(Routes.Reflection(), "back");
							}}
						>
							{t("modal.reflection.question.completed.cancel")}
						</Button>
					</div>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
