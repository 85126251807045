import {
	IonCheckbox,
	IonHeader,
	IonIcon,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonSkeletonText,
	IonSpinner,
	IonText,
	useIonRouter,
} from "@ionic/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { debounce, isEqual } from "lodash-es";
import { z } from "zod";
import { FormTextarea } from "@/components/Form/Textarea";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type MixedPostReflectionAnswer, type PostReflectionAnswer } from "@/lib/backend/types";
import {
	getPostReflectionAnswer,
	useExtractedMutation,
	useExtractedQuery,
} from "@/lib/backend/utils";
import { xMarkIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { paperAirplaneIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function ModalReflectionQuestionFirstAnswer() {
	const { t, i18n } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionQuestionFirstAnswer();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const textareaRef = useRef<HTMLIonTextareaElement>(null);
	const queryClient = useQueryClient();
	const router = useIonRouter();
	const [myData, { mutation: mutationMyData }] = useMyData();

	const mutation = useExtractedMutation({
		mutationKey: ["reflection", "answer"],
		fn: ({ id, ...variables }: { id: string } & PostReflectionAnswer) =>
			backendService.reflection.reflectionControllerAnswerQuestion(id, variables, {
				format: "text",
			}),
	});
	const queryQuestion = useExtractedQuery({
		queryKey: ["reflection", modal.id!] as const,
		fn: (context) =>
			backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!modal.id,
	});

	useEffect(() => {
		if (!modal.isOpen) {
			return;
		}

		if (!modal.id || (!queryQuestion.isLoading && !queryQuestion.data)) {
			modalRef.current?.dismiss();
		}
	}, [modal.isOpen, modal.id, queryQuestion.isLoading, queryQuestion.data]);

	const id = modal.id!;
	const question = queryQuestion.data!;
	const initialValues = myData.reflection.questions[id];

	const form = useZodForm<Omit<MixedPostReflectionAnswer, "questionType">>({
		defaultValues: initialValues ?? { content: "", options: [] },
		validators: {
			onSubmit:
				question?.questionType === "MULTIPLE_CHOICE"
					? z.object({
							options: z
								.array(z.string())
								.min(1, t("fields.options.errors.min", { count: 1 }))
								.max(
									question?.selectOptionLimit ?? 1,
									t("fields.options.errors.max", {
										count: question?.selectOptionLimit ?? 1,
									}),
								),
						})
					: z.object({
							content: z.string().min(3, t("fields.answer.errors.min", { count: 3 })),
						}),
		},
		onSubmit: ({ value, formApi }) =>
			mutation.mutateAsync(
				{
					id,
					...getPostReflectionAnswer({
						questionType: question.questionType,
						...value,
					}),
				},
				{
					onSuccess: (_, variables) => {
						mutationMyData.mutate((draft) => void delete draft.reflection.questions[variables.id]);

						if (!question.followUpQuestion) {
							present("reflectionQuestionCompleted", { id: variables.id });
						}

						modalRef.current?.dismiss();
						formApi.reset();
						queryClient.invalidateQueries({ queryKey: ["reflection"] });
						router.push(Routes.ReflectionQuestion({ id: variables.id }));
					},
				},
			),
	});

	useEffect(
		() =>
			form.store.subscribe(
				debounce(() => {
					// FUTURE: This fires twice on change, no big deal, but not super nice
					if (
						!!id &&
						form.store.state.isDirty &&
						!isEqual(form.store.state.values, initialValues)
					) {
						mutationMyData.mutate(
							(draft) => void (draft.reflection.questions[id] = form.store.state.values),
						);
					}
				}, 250),
			),
		[form.store, id, JSON.stringify(initialValues)],
	);

	if (!id || !question) {
		return <></>;
	}

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen && !!modal.id}
			onWillPresent={() => present("reflectionQuestionFirstAnswer")}
			onDidPresent={() => textareaRef.current?.setFocus()}
			onWillDismiss={() => dismiss("reflectionQuestionFirstAnswer", { id: null })}
			onDidDismiss={() => {
				mutation.reset();
				form.reset();
				queryQuestion.refetch();
			}}
		>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-py-2">
					<Button
						slot="primary"
						className="touch-target min-h-0 ion-bg-a-brown-300 ion-bg-brown-300 ion-bg-f-brown-300 ion-text-a-brown-700 ion-text-brown-700 ion-text-f-brown-700 ion-text-h-brown-700 ion-rounded-full ion-p-2.5"
						onClick={() => modalRef.current?.dismiss()}
					>
						<IonIcon slot="icon-only" icon={xMarkIcon} className="size-5" />
					</Button>
				</Toolbar>
			</IonHeader>
			<Content inModal className="part-scroll:gap-0 part-scroll:p-4 part-scroll:pt-2">
				<form
					className="flex flex-1 flex-col gap-4 rounded-3xl border border-brown-200 bg-brown-100 p-4 contain-paint"
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						void form.handleSubmit();
					}}
				>
					<div className="flex flex-1 flex-col gap-2 pt-2">
						{queryQuestion.isLoading ? (
							<div className="flex flex-col">
								<IonSkeletonText animated className="h-4 w-5/6" />
								<IonSkeletonText animated className="h-4 w-1/2" />
							</div>
						) : (
							<>
								<IonText className="text-pretty font-semibold text-brown-700">
									{question.question}
								</IonText>

								{(function () {
									switch (question.questionType) {
										case "MULTIPLE_CHOICE":
											return (
												<form.Field
													key="options"
													name="options"
													children={(field) =>
														question.selectOptionLimit > 1 ? (
															<div
																className={cx(
																	"flex flex-col gap-2",
																	field.state.meta.isTouched && "ion-touched",
																	!!field.state.meta.errors &&
																		field.state.meta.errors.length > 0 &&
																		"ion-invalid",
																)}
															>
																{question.options.map((option, index) => {
																	const checked = field.state.value.includes(option);

																	return (
																		<IonCheckbox
																			key={index}
																			value={option}
																			labelPlacement="end"
																			justify="start"
																			name={field.name}
																			checked={checked}
																			onIonChange={() => {
																				if (checked) {
																					field.removeValue(field.state.value.indexOf(option));
																				} else {
																					field.pushValue(option);
																				}
																			}}
																			onIonBlur={field.handleBlur}
																			disabled={
																				(question.selectOptionLimit <= field.state.value.length &&
																					!checked) ||
																				field.state.meta.isValidating ||
																				field.form.state.isSubmitting
																			}
																			className="rounded-xl bg-brown-200 p-3 text-base font-normal text-brown-700 part-[label]:overflow-auto part-[label]:whitespace-normal"
																		>
																			{option}
																		</IonCheckbox>
																	);
																})}
																{!!field.state.meta.errors && !!field.state.meta.errors.length && (
																	<span className="text-sm font-semibold text-danger-500">
																		{getErrorText({
																			language: i18n.language,
																			errors: field.state.meta.errors,
																		})}
																	</span>
																)}
															</div>
														) : (
															<IonRadioGroup
																name={field.name}
																value={field.state.value}
																onIonChange={(event) =>
																	field.setValue([String(event.detail.value)])
																}
																onBlur={field.handleBlur}
																className={cx(
																	"flex flex-col gap-2",
																	field.state.meta.isTouched && "ion-touched",
																	!!field.state.meta.errors &&
																		field.state.meta.errors.length > 0 &&
																		"ion-invalid",
																)}
															>
																{question.options.map((option, index) => (
																	<IonRadio
																		key={index}
																		value={option}
																		labelPlacement="end"
																		justify="start"
																		disabled={
																			field.state.meta.isValidating || field.form.state.isSubmitting
																		}
																		className="rounded-xl bg-brown-200 p-3 text-base font-normal text-brown-700 part-[label]:overflow-auto part-[label]:whitespace-normal"
																	>
																		{option}
																	</IonRadio>
																))}
																{!!field.state.meta.errors && !!field.state.meta.errors.length && (
																	<span className="text-sm font-semibold text-danger-500">
																		{getErrorText({
																			language: i18n.language,
																			errors: field.state.meta.errors,
																		})}
																	</span>
																)}
															</IonRadioGroup>
														)
													}
												/>
											);
										case "NUMBER":
										case "TEXT":
										case "EMAIL":
										case "DATA":
										default:
											return (
												<form.Field
													key="content"
													name="content"
													children={(field) => (
														<FormTextarea
															ref={textareaRef}
															field={field}
															placeholder={t("fields.answer.placeholder")}
															inputMode="text"
															autocapitalize="sentences"
															onKeyDown={async (event) => {
																if (event.metaKey && event.code === "Enter") {
																	field.handleChange(
																		String(
																			(event.target as unknown as { value: string })?.value ?? "",
																		),
																	);

																	void form.handleSubmit();
																}
															}}
															className="flex flex-1 flex-col text-brown-700 [&_textarea]:!-mx-4 [&_textarea]:!box-content [&_textarea]:!px-4"
														/>
													)}
												/>
											);
									}
								})()}
							</>
						)}
					</div>

					<div className="flex justify-end">
						<form.Subscribe
							selector={(state) => [state.canSubmit, state.isSubmitting]}
							children={([canSubmit, isSubmitting]) => (
								<Button
									type="submit"
									disabled={!canSubmit}
									shape="round"
									color="primary"
									size="default"
									className="touch-target min-h-0 part-native:size-12 part-native:min-h-0 part-native:rounded-full part-native:p-1 part-native:text-sm"
								>
									{isSubmitting ? (
										<IonSpinner slot="icon-only" />
									) : (
										<IonIcon slot="icon-only" icon={paperAirplaneIcon} className="size-3/5" />
									)}
								</Button>
							)}
						/>
					</div>
				</form>
			</Content>
		</IonModal>
	);
}
