import { IonTextarea, useIonViewDidEnter } from "@ionic/react";
import { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { type FieldApi } from "@tanstack/react-form";
import { getErrorText } from "@/components/Form/utils";
import { cx } from "@/lib/style/cva.config";
import { mergeRefs } from "@/utils/mergeRefs";

export type FormTextareaProps = {
	field: FieldApi<any, any, any, any, any>;
	autofocusOnIonViewDidEnter?: boolean;
};

/**
 * Custom type for IonTextarea props, excluding 'autoCapitalize'.
 *
 * This type is created to handle the capitalization mismatch between React props and IonTextarea
 * attributes. It omits 'autoCapitalize' from the original IonTextarea props, allowing us to
 * redefine it with the correct capitalization expected by the IonTextarea component.
 *
 * @see https://ionicframework.com/docs/api/textarea#attributes
 */
type IonTextareaProps = Omit<React.ComponentProps<typeof IonTextarea>, "autoCapitalize">;

export const FormTextarea = forwardRef(function FormTextarea(
	{ field, autofocusOnIonViewDidEnter, ...props }: IonTextareaProps & FormTextareaProps,
	ref: React.ForwardedRef<HTMLIonTextareaElement>,
) {
	const { i18n } = useTranslation();
	const textareaRef = useRef<HTMLIonTextareaElement>(null);

	useIonViewDidEnter(() => {
		if (autofocusOnIonViewDidEnter) {
			textareaRef.current?.setFocus();
		}
	});

	return (
		<IonTextarea
			ref={mergeRefs([ref, textareaRef])}
			name={field.name}
			errorText={getErrorText({ language: i18n.language, errors: field.state.meta.errors })}
			value={field.state.value}
			defaultValue={field.state.value}
			onIonChange={(event) => field.handleChange(String(event.detail.value))}
			onIonBlur={field.handleBlur}
			readonly={field.state.meta.isValidating || field.form.state.isSubmitting}
			labelPlacement="stacked"
			clearOnEdit={false}
			{...props}
			className={cx(
				"stacked",
				field.state.meta.isTouched && "ion-touched",
				!!field.state.meta.errors && field.state.meta.errors.length > 0 && "ion-invalid",
				props.className,
			)}
		/>
	);
});
