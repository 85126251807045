import { IonCheckbox, IonHeader, IonIcon, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { z } from "zod";
import { useShallow } from "zustand/shallow";
import AppleBlack_nl from "@/assets/apple/black/nl.svg";
import AppleBlack_en from "@/assets/apple/black/us.svg";
import SplashIcon from "@/assets/splash_icon.svg";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type PatchFriendCheckEmailDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { Toast } from "@/lib/capacitor/Toast";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { useConfig } from "@/lib/posthog/config";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { useFriendCheckStore } from "@/lib/store/friend-check";
import { cx } from "@/lib/style/cva.config";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

const MotionButton = motion.create(Button);

export function SharedFriendCheckThankYou() {
	const { t, i18n } = useTranslation();
	const config = useConfig();
	const state = useFriendCheckStore(
		useShallow((state) => ({
			...state.result,
			friendId: state.friendId,
			friendHash: state.friendHash,
			email: state.email,
		})),
	);

	const [me] = useMe();

	const mutation = useExtractedMutation({
		mutationKey: ["friend-check", "update"],
		fn: ({
			friendId,
			friendHash,
			...data
		}: { friendId: string; friendHash: string } & PatchFriendCheckEmailDto) =>
			backendService.friendcheck.friendcheckControllerPatchPublicFriendCheckWithEmail(
				friendId,
				friendHash,
				data,
			),
	});

	const form = useZodForm<{
		email: string;
		acceptsEmail: boolean;
	}>({
		defaultValues: {
			email: "",
			acceptsEmail: false,
		},
		onSubmit: ({ value }) => {
			if (!state.patchToken || !state.submissionId) {
				return;
			}

			mutation.mutate(
				{
					friendId: state.friendId,
					friendHash: state.friendHash,
					email: value.email,
					patchToken: state.patchToken,
					submissionId: state.submissionId,
				},
				{
					onSuccess: () => Toast.show({ text: t("page.shared-friend-check.thank-you.message") }),
				},
			);
		},
	});

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<div slot="start" className="flex min-w-0 items-center justify-start gap-3">
						<img
							src={SplashIcon}
							className="-mx-2 -mb-4 -mt-[1.125rem] size-12 min-h-12 min-w-12 select-none drag-none"
						/>
						<span className="truncate text-start text-base font-semibold text-brown-700">
							{t("seo.title")}
						</span>
					</div>
					<motion.a
						slot="end"
						href="https://imperfect.so"
						target="_blank"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "spring", delay: 0.5 }}
						className="flex min-h-8 items-center rounded-3xl bg-brown-100 pe-1 ps-3 text-sm font-semibold text-brown-700 hover:bg-brown-100/75 focus:bg-brown-100/75 active:bg-brown-100/75 plt-native:hidden"
					>
						{t("page.shared-friend-check.header.more-information")}
						<IonIcon icon={arrowRightIcon} className="size-4 p-2" />
					</motion.a>
					<MotionButton
						slot="end"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "spring", delay: 0.5 }}
						routerLink={Routes.App()}
						routerDirection="none"
						className="hidden min-h-8 items-center rounded-3xl text-sm font-semibold ion-bg-a-brown-100/75 ion-bg-brown-100 ion-bg-f-brown-100/75 ion-bg-h-brown-100/75 ion-text-brown-700 ion-p-0 ion-pe-1 ion-ps-3 plt-native:flex"
					>
						{t("to-app")}
						<IonIcon slot="end" icon={arrowRightIcon} className="size-4 p-2" />
					</MotionButton>
				</Toolbar>
			</IonHeader>

			<Content>
				<div className="flex flex-1 flex-col items-center justify-center gap-2 *:text-center">
					<h1 className="text-4xl font-bold text-brown-700">
						{t("page.shared-friend-check.thank-you.title")}
					</h1>
					<span className="text-base font-normal text-brown-600">
						{t("page.shared-friend-check.thank-you.description")}
					</span>

					{config.friendCheckAppStoreLink === "control" && (
						<motion.a
							href="https://imperfect.so/redirect/ios"
							target="_blank"
							rel="noreferrer"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ type: "spring", delay: 0.5 }}
							className="mt-12 min-h-10 shrink-0 plt-native:hidden"
						>
							<img src={{ nl: AppleBlack_nl, en: AppleBlack_en }[i18n.language]} />
						</motion.a>
					)}
				</div>

				{!me &&
					(config.friendCheckEmail === "patch" || !state.email) &&
					!!state.patchToken &&
					!!state.patchUntil &&
					!!state.submissionId && (
						<motion.form
							className="flex flex-col gap-4 rounded-2xl bg-white p-3"
							initial={{ opacity: 0, scale: 0.75 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ type: "spring", delay: 1 }}
							onSubmit={(event) => {
								event.preventDefault();
								event.stopPropagation();
								form.validateAllFields("submit");
								void form.handleSubmit();
							}}
						>
							<form.Field
								name="email"
								validators={{ onSubmit: z.string().email().or(z.string().nullish()) }}
								children={(field) => (
									<FormInput
										field={field}
										type="email"
										autocomplete="email"
										disabled={
											field.form.state.isSubmitted ||
											field.state.meta.isValidating ||
											field.form.state.isSubmitting
										}
										label={t("page.shared-friend-check.fields.email.label")}
										placeholder={t("fields.email.placeholder")}
										className="[&_.input-wrapper]:!gap-4 [&_.label-text-wrapper]:!mt-0 [&_.label-text-wrapper]:!transform-none [&_.native-wrapper]:!border-brown-200"
									/>
								)}
							/>
							<form.Field
								name="acceptsEmail"
								validators={{
									onSubmit: z.coerce
										.boolean()
										.refine(
											(value) => (form.getFieldValue("email") ? value === true : true),
											t("page.shared-friend-check.fields.email.errors.confirmation-required"),
										),
								}}
								children={(field) => (
									<div
										className={cx(
											"flex flex-col gap-2 rounded-xl border-2 border-brown-200 p-3 text-sm text-brown-700",
											field.state.meta.isTouched && "ion-touched",
											!!field.state.meta.errors &&
												field.state.meta.errors.length > 0 &&
												"ion-invalid",
										)}
									>
										<IonCheckbox
											value={field.state.value}
											labelPlacement="end"
											justify="start"
											alignment="center"
											name={field.name}
											checked={field.state.value}
											onIonChange={(event) => field.handleChange(event.detail.checked)}
											onIonBlur={field.handleBlur}
											disabled={
												field.form.state.isSubmitted ||
												field.state.meta.isValidating ||
												field.form.state.isSubmitting
											}
											className="font-semibold ion-border-brown-200 ion-checkbox-bg-transparent part-container:border-brown-200"
										>
											{t("page.shared-friend-check.fields.email.description")}
										</IonCheckbox>
										{!!field.state.meta.errors && !!field.state.meta.errors.length && (
											<span className="ms-9 text-xs text-danger-500">
												{getErrorText({ language: i18n.language, errors: field.state.meta.errors })}
											</span>
										)}
									</div>
								)}
							/>

							<form.Subscribe
								selector={(state) => [state.isSubmitted, state.canSubmit, state.isSubmitting]}
								children={([isSubmitted, canSubmit, isSubmitting]) => (
									<Button
										type="submit"
										className="text-lg font-bold"
										disabled={isSubmitted || !canSubmit || isSubmitting}
									>
										{t("save")}
									</Button>
								)}
							/>
						</motion.form>
					)}

				{config.friendCheckAppStoreLink === "custom" && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "spring", delay: 0.5 }}
						className="flex gap-4 rounded-2xl border border-[#E6E6E6] bg-white p-4 font-[system-ui] plt-native:hidden"
					>
						<img
							src={SplashIcon}
							className="size-16 select-none rounded-[1.14288rem] bg-gradient-to-b from-gradient-from to-gradient-to drag-none"
						/>
						<div className="flex flex-col gap-2">
							<div className="flex flex-col">
								<strong className="text-lg tracking-tight text-gray-700">{t("seo.title")}</strong>
								<small className="text-sm tracking-tight text-[#8E97A6]">
									{t("seo.description")}
								</small>
							</div>
							<a
								href="https://imperfect.so/redirect/ios"
								target="_blank"
								rel="noreferrer"
								className="w-fit rounded-full bg-[#0A84FF] px-4 py-1 text-[0.9365rem] font-bold leading-5 tracking-[-0.03125rem] text-white"
							>
								{t("open-in-app-store")}
							</a>
						</div>
					</motion.div>
				)}
			</Content>
		</IonPage>
	);
}
